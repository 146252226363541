import { Button, Typography } from '@@/components/Elements';
import { useEffect, useRef, useState } from 'react';

import { MutationHook } from '@/components';

type requestProps = {
	values?: {
		method: string;
	};
	ephemeralToken?: string;
};
type ResendCodeProps = {
	verifyError: boolean;
	setIsVerificationError: (value: boolean) => void;
	setCodeResentConfirm: (value: boolean) => void;
	resendMutation: MutationHook;
	requestProps: requestProps;
	isRecoveryCodeLogin?: boolean;
};

export const ResendCode = (props: ResendCodeProps) => {
	const {
		verifyError,
		setIsVerificationError,
		setCodeResentConfirm,
		resendMutation,
		requestProps,
		isRecoveryCodeLogin = false,
	} = props;
	const [doResend, { isSuccess: resendSuccess, isLoading: resendLoading }] = resendMutation();
	const countdownTimer = useRef<{ reset: () => void } | null>(null);
	const [countdown, setCountdown] = useState(0);
	const countdownTimerSeconds = 30;

	const startCountdown = (duration: number) => {
		let timer: NodeJS.Timeout;
		const resetCountdown = () => {
			clearInterval(timer);
			setCountdown(duration);
			timer = setInterval(() => {
				setCountdown((prev) => {
					if (prev <= 1) {
						clearInterval(timer);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
		};

		resetCountdown();

		return {
			reset: resetCountdown,
		};
	};

	useEffect(() => {
		if (verifyError) {
			setIsVerificationError(true);
			countdownTimer.current?.reset();
		}
	}, [verifyError]);

	useEffect(() => {
		countdownTimer.current = startCountdown(countdownTimerSeconds);
	}, []);

	useEffect(() => {
		if (resendSuccess) setCodeResentConfirm(true);
	}, [resendSuccess]);

	const doResendCode = () => {
		// request to resend the verfication code
		setIsVerificationError(false);
		setCodeResentConfirm(false);
		doResend(requestProps);
		countdownTimer.current = startCountdown(countdownTimerSeconds);
	};

	if (isRecoveryCodeLogin) return null;

	return (
		<>
			<Typography className='pt-8'>
				Receiving the code can take a few minutes.
				<br />
				Didn’t receive it?
				<Button
					variant='primaryTransparent'
					className='!inline !p-0 !pl-1'
					onClick={doResendCode}
					disabled={countdown > 0 || resendLoading}
					isLoading={resendLoading}
				>
					Resend code
				</Button>
			</Typography>
			{countdown > 0 && (
				<Typography className='text-secondaryTextDark pt-4'>
					Please retry after {countdown} seconds
				</Typography>
			)}
		</>
	);
};
