import { API_METHODS } from '@/globalConsts';
import { baseApi, baseApiNoAutoLogout } from '@/lib/rtkQuery/baseApi';

import { AUTH } from '../consts';
import {
	ActivateConfirmMfaRequest,
	ActivateConfirmMfaResponse,
	ActivateMfaRequest,
	ActivateMfaResponse,
	AuthResponse,
	LoginRequest,
	LoginWithCodeRequest,
	LogoutResponse,
	NewPasswordRequest,
	ResendCodeRequest,
	ResetPasswordRequest,
	ResetPasswordResponse,
	TermsAndConditionResponse,
	ValidateTokenRequest,
	ValidateTokenResponse,
} from '../types';

export const authApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['auth', 'session', 'terms'] })
	.injectEndpoints({
		endpoints: (build) => ({
			loginUser: build.mutation<AuthResponse, LoginRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.LOGIN,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			logoutUser: build.mutation<LogoutResponse, void>({
				query: () => {
					return {
						url: AUTH.APIS.LOGOUT,
						method: API_METHODS.GET,
					};
				},
			}),
			resetPassword: build.mutation<ResetPasswordResponse, ResetPasswordRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.RESET_PASSWORD,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			validateToken: build.query<ValidateTokenResponse, ValidateTokenRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.VALIDATE_TOKEN,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			newPasswordConfirmation: build.mutation<ResetPasswordResponse, NewPasswordRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.NEW_PASSWORD_CONFIRMATION,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			fetchTerms: build.query<TermsAndConditionResponse, void>({
				query: () => {
					return {
						url: AUTH.APIS.FETCH_TERMS,
						method: API_METHODS.GET,
					};
				},
				providesTags: ['terms'],
			}),
			activateMfa: build.mutation<ActivateMfaResponse, ActivateMfaRequest>({
				query: ({ values }) => {
					return {
						url: `v2/auth/${values.method}/activate/`,
						method: API_METHODS.POST,
					};
				},
			}),
			activateConfirmMfa: build.mutation<
				ActivateConfirmMfaResponse,
				ActivateConfirmMfaRequest
			>({
				query: ({ method, values }) => {
					return {
						url: `v2/auth/${method}/activate/confirm/`,
						method: API_METHODS.POST,
						body: { code: values.code },
					};
				},
			}),
			resendCode: build.mutation<void, ResendCodeRequest>({
				query: ({ ephemeralToken }) => {
					return {
						url: 'v2/auth/login/resend-code/',
						method: API_METHODS.POST,
						body: {
							ephemeral_token: ephemeralToken,
						},
					};
				},
			}),
		}),
	});

export const authVerifyApi = baseApiNoAutoLogout
	.enhanceEndpoints({ addTagTypes: ['auth', 'session'] })
	.injectEndpoints({
		endpoints: (build) => ({
			loginWithCode: build.mutation<AuthResponse, LoginWithCodeRequest>({
				query: ({ ephemeralToken, values }) => {
					return {
						url: 'v2/auth/login/code/',
						method: API_METHODS.POST,
						body: {
							ephemeral_token: ephemeralToken,
							code: values.code,
						},
					};
				},
			}),
		}),
	});

export const {
	useLoginUserMutation,
	useLogoutUserMutation,
	useResetPasswordMutation,
	useValidateTokenQuery,
	useNewPasswordConfirmationMutation,
	useFetchTermsQuery,
	useActivateMfaMutation,
	useActivateConfirmMfaMutation,
	useResendCodeMutation,
} = authApi;

export const { useLoginWithCodeMutation } = authVerifyApi;
